$(document).on('ready', function() {
	preselectCity();
	$('.nf-tabs-form__group select').select2({
		sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
		dropdownCssClass: 'nf-tabs-form-select-dropdown'
	});
});
$('.nf-tabs-control__item').on('click', function () {
	let self = $(this),
		id = self.data('id');
	if (!self.hasClass('is-active')) {
		self.addClass('is-active').siblings('.is-active').removeClass('is-active');
		$('.nf-tabs-item[data-id="' + id + '"]').show().siblings('.nf-tabs-item').hide();
		$('.nf-tabs-item-title[data-id="' + id + '"]').addClass('is-active').siblings('.is-active').removeClass('is-active');
	}
})
$('.js_test').on('click', function () {
	$('.nf-tabs-form__row--status').addClass('is-show');
})
$('.nf-tabs-form__row--status').on('click', function () {
	$(this).removeClass('is-show');
})
$('.nf-tabs-item-title').on('click', function () {
	let self = $(this),
		id = self.data('id');
	if (!self.hasClass('is-active')) {
		self.addClass('is-active').siblings('.is-active').removeClass('is-active');
		$('.nf-tabs-item[data-id="' + id + '"]').slideDown().siblings('.nf-tabs-item').slideUp();
		$('.nf-tabs-control__item[data-id="' + id + '"]').addClass('is-active').siblings('.is-active').removeClass('is-active');
	}
})
$('#return_flag').on('change', function () {
	let val = $(this).val();
	if (val == 1) {
		$('.js_return-ticket-date').show()
	} else {
		$('.js_return-ticket-date').hide()
	}
})

$('.js_places-trigger').on('click', function () {
	$('.js_places-dropdown').toggle();
})
$('.js_places-control').on('click', function () {
	if (!$(this).attr('disabled')) {
		const min = 1,
			max = 5;

		let self = $(this),
			func = self.data('func'),
			input = self.siblings('.js_places-input'),
			count = parseInt(input.val());

		switch (func) {
			case 'dec':
				if (count == max) {
					enableButton(self);
				}
				count -= 1;
				setValue(count, input);
				if (count == min) {
					disableButton(self);
				}
				break;
			case 'inc':
				if (count == min) {
					enableButton(self);
				}
				count += 1;
				setValue(count, input);
				if (count == max) {
					disableButton(self);
				}
				break;
		}

		unitedPlaces();
	}
})

$('.js_custom-time').on('change', function(){
	let self = $(this);

	if ( !self.is(':checked') ) {
		self.closest('.nf-tabs-form__row').find('.js_time-pick').val('00:00');
	}
})

$('.js_station_change').on('click', function() {
	let cityFrom = $('.js_city_from_id'),
		cityTo = $('.js_city_to_id');

	if ( cityFrom.val() != 0 && cityTo.val() != 0 ) {
		$(this).attr('disabled', true);
		cityFrom.attr('data-changed', cityFrom.val())
			.val(cityTo.val())
			.trigger('change');
	}
})

$('.nf-tabs-control__item[data-id="booking"]').trigger('click');

function setValue(count, input) {
	input.val(count);
}

function disableButton(btn) {
	btn.prop('disabled', true);
}

function enableButton(btn) {
	btn.siblings('button').prop('disabled', false);
}

function unitedPlaces() {
	let value = '',
		prevValue = ': ',
		showComma = true;

	$('.js_places-item').each(function (index) {
		let item = $(this);
		if (item.data('count') > 0) {
			if (index != 0 && showComma) {
				value += ', ';
			}
			value += item.data('text') + ' - ' + item.data('count');
		} else {
			showComma = false;
		}
	}).promise().done(function () {
		if (value.length > 0) {
			value = prevValue + value;
		}
		$('.js_places-trigger').text(value);
	})
}

function preselectCity() {
	if ( getUrlParameter('from_id') && getUrlParameter('to_id') ) {
		$('.js_city_from_id').attr('data-preselect', getUrlParameter('to_id'))
			.val(getUrlParameter('from_id'))
			.trigger('change');
	}
}

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
}